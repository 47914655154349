<template>
  <el-container class="announcement-types h-full">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Dashboard Contents')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <component v-bind:is="component"></component>
  </el-container>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import { ANNOUNCEMENT_TYPE } from "@/constants/announcementTypes";

export default {
  name: "AnnouncementsSystemIndex",
  components: {
    PageHeader
  },

  data() {
    return {
      selectedComponent: ANNOUNCEMENT_TYPE.TUTORIAL + "s",
      selectedIndex: null,
      navItems: [
        {
          tag: ANNOUNCEMENT_TYPE.TUTORIAL,
          label: this.prettifyLabel(ANNOUNCEMENT_TYPE.TUTORIAL, true),
          active: true,
          path: "tutorials",
          icon: "icon-tutorial.svg"
        },
        {
          tag: ANNOUNCEMENT_TYPE.WHATS_NEW,
          label: this.prettifyLabel(ANNOUNCEMENT_TYPE.WHATS_NEW),
          active: false,
          path: "whats-new",
          icon: "icon-whats-new.svg"
        }
      ]
    };
  },

  computed: {
    component() {
      return this.importComponent(this.selectedComponent);
    },
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  mounted() {
    this.setAnnouncementType(ANNOUNCEMENT_TYPE.TUTORIAL);
  },

  methods: {
    ...mapActions("announcements", {
      setAnnouncementType: "setAnnouncementType"
    }),

    prettifyLabel(announcementType, pluralCheck = false) {
      let transformedLabel = _.startCase(
        _.split(announcementType, "_").join(" ")
      );
      transformedLabel = pluralCheck
        ? transformedLabel + "s"
        : transformedLabel;
      return __(transformedLabel);
    },

    importComponent(path) {
      return () =>
        import("@/views/system/announcements/" + path + "/index.vue");
    },

    handleNavItemChanged(navItem) {
      this.setAnnouncementType(navItem.tag);
      this.selectedComponent = navItem.path;
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.announcement-types {
  display: flex;
  flex-direction: column;
}
</style>
